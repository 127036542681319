<template>
  <b-card
    body-class="pb-50"
  >
    <h6>{{ title }}</h6>
    <h2 class="font-weight-bolder mb-1">
      {{ kFormatter(data) }}
    </h2>
    <!-- chart -->
    <!-- <vue-apex-charts
      height="70"
      :options="statisticsOrder.chartOptions"
      :series="data.series"
    /> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BCard,
    // VueApexCharts,
  },
  props: {
    data: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    console.log(this.data)
    return {
    }
  },
  methods: {
    kFormatter(num) {
      if (num !== 0) {
       const numTemp = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))
       return Math.abs(num) > 999 ? `${numTemp}k` : num
      }
      return num
    },
  },
}
</script>
